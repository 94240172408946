import React, { FC } from "react"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import styled from "styled-components"
import Horizon from "~/components/AboutPage/utils/Horizon"
import ExternalLink from "~/components/ExternalLink"
import { StaticImage } from "gatsby-plugin-image"
import Title from "~/components/AboutPage/utils/Title"
import { Link } from "gatsby"

const Wrapper = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  padding-top: 60px;
  @media screen and (max-width: 900px) {
    padding-top: 30px;
  }
  font-size: 1.25rem;
  ${({ theme }) => theme.media.lm`
        font-size: 1rem;
    `}
`
const MainTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.title};
`
const Totteku = styled.span`
  font-family: "Reggae One", cursive;
`
const ButtonBox = styled.div`
  margin: 0 40px 0 40px;
  padding: 30px;
  background: #f9cb9a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 900px) {
    padding: 20px;
  }
`
const Text = styled.div`
  line-height: 40px;
`
const TextBox = styled.div`
  margin: 50px 0 0 0;
  padding: 0 20px 0 20px;
  text-align: left;
`
const PhotoBox = styled.div`
  padding-top: 10px;
  display: block;
`
const TextNews = styled.div`
  padding: 10px 10px 40px 10px;
`
const TextLink = styled.div`
  color: blue;
  &:hover {
    opacity: 0.5;
  }
`

const Service: FC = () => {
  return (
    <>
      <Head title="Service" />
      <Layout>
        <Wrapper>
          <MainTitle>SERVICE</MainTitle>
          <Title>
            「<Totteku>撮っテク！</Totteku>」
          </Title>
          <ExternalLink link="https://totteku.tourism-project.com/">
            <ButtonBox>
              <Totteku>撮っテク！</Totteku>のHPはこちら
            </ButtonBox>
          </ExternalLink>

          <Horizon />
          <TextBox>
            <Text>
              現在、スマホでスタンプラリーができるアプリを作っています！
              この開発したアプリは、石川県の湯涌温泉と連携して
              地域を盛り上げるために使っています！
            </Text>
          </TextBox>
          <PhotoBox>
            <StaticImage
              src="../../static/images/totteku-play.JPG"
              alt="撮っテク使用画像"
              width={800}
              height={450}
            />
          </PhotoBox>
          <TextBox>
            <Text>
              写真のようにスポットの写真を撮ることによって、
              アプリの地図上のスタンプが埋まっていきます！
              {/* 撮った写真を用いて応募するフォトコンテストも計画中しています！
              撮った写真をチェキにして参加者に配布しています。 */}
              詳しくは、撮っテク！のWebサイトにてチェックしてみてください！
            </Text>
          </TextBox>

          <Horizon />
          <Title>北國新聞に載りました！</Title>
          <TextNews>
            2022年1月31日に北國新聞にて、私たちの活動が取り上げられました。▼
          </TextNews>
          <ExternalLink link="https://www.hokkoku.co.jp/articles/-/647156">
            <TextLink data-level="link">
              北國新聞による私たちの活動内容の記事
            </TextLink>
          </ExternalLink>

          {/* <Horizon />
          <PhotoBox>
            <StaticImage
              src="../../static/images/photo-con.png"
              alt="フォトコンテスト"
              width={800}
              height={450}
            />
          </PhotoBox>
          <TextBox>
            <Text>
              今後、湯涌温泉で開催されるフォトラリーに参加してもらうと
              フォトコンテストに撮った写真を応募することができます！
            </Text>
          </TextBox> */}

          <Horizon />
          <Text>
            <Title>使用技術について▼</Title>
            <Link to="/about">
              <TextLink data-level="link">
                使用技術について気になる方はABOUTページ
              </TextLink>
            </Link>
          </Text>

          <Horizon />
          <Text>
            <Title>
              <Totteku>撮っテク！</Totteku>のHPはこちら▼
            </Title>
            <ExternalLink link="https://totteku.tourism-project.com/">
              <StaticImage
                src="../../static/images/app.png"
                alt="撮っテクHP"
                width={800}
                height={450}
                style={{ borderRadius: 48 }}
              />
            </ExternalLink>
          </Text>
          <Horizon />
        </Wrapper>
      </Layout>
    </>
  )
}

export default Service
